import React from "react"
import { ArrowLeft, MapPin } from "react-feather"
import { Job } from "utils"
import { Link } from "gatsby-plugin-react-i18next"
import { PrimaryBtn } from "components/library"

type P = {
	job: Job
}

export const PositionBrief = ({ job }: P) => {
	return (
		<div className="flex flex-col gap-8">
			<Link to="/careers" className="flex gap-2 items-center">
				<ArrowLeft size={18} />
				<span>Back</span>
			</Link>

			<div className="flex flex-col gap-4">
				<p className="text-lg font-semibold">{job.team}</p>
				<span className="text-[40px] sm:text-5xl lg:text-6xl">{job.position}</span>
			</div>

			<div className="flex flex-col sm:flex-row gap-4 sm:gap-8">
				<span className="flex gap-4 items-center">
					<MapPin />
					<span className="text-xl">{job.location.join(", ")}</span>
				</span>

				<span className="flex gap-4 items-center">
					<MapPin />
					<span className="text-xl">{job.job_type}</span>
				</span>
			</div>

			<a
				href="https://forms.gle/isdqNEivWCtBjLnw9"
				className="w-full sm:max-w-max"
				target="_blank"
			>
				<PrimaryBtn tabIndex={-1} className="w-full sm:w-auto">
					Apply for this role
				</PrimaryBtn>
			</a>
		</div>
	)
}
